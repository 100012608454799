

const permission = {
    state: {
        menuList: []

    },
    mutations: {
        setMenuList(state, e) {
            state.menuList = e
        }
    },
    actions: {
        generateRoutes({ commit }, menuList = []) {
            return new Promise((resolve) => {
                let menus = menuList.filter(i => i.parentId == 0)
                let routerList = filterRouter(menus)
                let list = filterMenu(menus, menuList)
                commit('setMenuList', list)
                resolve(routerList)
            })
        }
    }
}


function filterRouter(menuList) {
    let routerList = []
    menuList.forEach(i => {
        if (i.sysMenus) {
            i.sysMenus.forEach(j => {
                if (j.path == '/property/trace' || j.path == '/dataMonitor/trace') {
                    routerList.push({
                        name: j.path,
                        path: j.path,
                        component: (resolve) => require([`@/components/VehicleTrace`], resolve)
                    })
                } else {
                    routerList.push({
                        name: j.path,
                        path: j.path,
                        component: loadView(j.component)
                    })
                }

            })
        }
    })
    return [{
        path: '/asyncRouter',
        name: 'asyncRouter',
        component: () => import('@/pages/Home'),
        meta: { title: '枫叶出行监控系统' },
        children: routerList
    }

    ]
}

function filterMenu(menuList, allMenuList) {
    menuList = JSON.parse(JSON.stringify(menuList))
    menuList.forEach(item => {
        if (item.sysMenus) {
            let menus = item.sysMenus.filter(i => (i.menuType == 'C' && i.visible == 0 && allMenuList.filter(j => j.menuId == i.menuId).length > 0))
            item.sysMenus = menus
        } else {
            item.sysMenus = []
        }
    })
    return menuList.filter(i => i.sysMenus.length > 0)
}

function loadView(view) {
    return (resolve) => require([`@/pages/${view}`], resolve)
}

export default permission