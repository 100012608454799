<template>
  <el-dialog
    :visible.sync="dialogState"
    :show-close="false"
    width="60%"
    :before-close="close"
  >
    <div slot="title" class="dialog_header">
      <span>{{ title }}</span>
      <i class="el-icon-close" style="font-size: 26px" @click="close" />
    </div>
    <slot></slot>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogState: true,
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  background: rgb(26, 36, 62);
  display: flex;
}
.dialog_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  color: #fff;
}
</style>