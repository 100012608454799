import router from './index'
import store from '@/store/index'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken, removeToken } from '@/utils/auth'

NProgress.configure({ showSpinner: false })

let isInit = false

router.beforeEach((to, from, next) => {
    NProgress.start()
    if (to.path == '/login') {
        isInit = false
        removeToken()
        next()
    }
    if (getToken()) {
        if (isInit) {
            if (to.path == '/') {
                next(store.state.permission.menuList[0].sysMenus[0].path)
            } else {
                next()
            }
        } else {
            store.dispatch('getUserInfo').then((menuList = []) => {
                store.dispatch('generateRoutes', menuList).then(routers => {
                    router.addRoutes(routers)
                    isInit = true
                    next({ ...to, replace: true })
                })
            })
        }
    } else {
        if (to.path == '/login') {
            isInit = false
            removeToken()
            next()
        } else {
            isInit = false
            next('/login')
        }
    }
})

router.afterEach(() => {
    NProgress.done()
})