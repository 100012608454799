import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)
Vue.use(VueRouter)
const routerTemp = [
]

const router = new VueRouter({
  base: "/",
  routes: [
    {
      path: '/login',
      name: 'Login',
      meta: {
        title: '枫叶出行监控系统'
      },
      component: resolve => require(['../pages/Login.vue'], resolve)
    },
    {
      path: '/home',
      name: 'Home',
      component: resolve => require(['../pages/Home.vue'], resolve),
      meta: { title: '枫叶出行监控系统' },
      children: routerTemp
    },
    {
      path: '/trace',
      name: 'Trace',
      component: resolve => require(['../components/VehicleTrace.vue'], resolve)
    },
    {
      path: '/position',
      name: 'Position',
      component: resolve => require(['../components/VehiclePosition.vue'], resolve)
    },
  ]
})

// router.beforeEach((to, from, next) => {
//   next()
//   // if (to.path == '/login' || to.path == '/regist'
//   //   || to.path == '/403' || to.path == '/404') {
//   //   next()
//   // } else {
//   //   if (localStorage.getItem("token")) {
//   //     if (to.path === '/home' || to.path == '/trace' || to.path == '/position') {
//   //       next()
//   //     } else {
//   //       let pageExist = false
//   //       routerTemp.some(item => {
//   //         if (item.path === to.path) {
//   //           pageExist = true
//   //           return true
//   //         }
//   //       })
//   //       if (pageExist) {
//   //         next()
//   //       } else {
//   //         next({
//   //           path: "/404"
//   //         })
//   //       }
//   //     }
//   //   } else {
//   //     next({
//   //       path: "/login"
//   //     })
//   //   }
//   // }
// })
export default router
